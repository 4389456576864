<template>
  <div class="wrapper">
    <!-- 车型-->
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="车型名称">
          <el-input placeholder="请输入" v-model="searchData.name" size="small"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="header-btn">
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.model_list_add)"
        @click="$router.push({ name: 'CarModelAdd' })"
        type="primary"
        icon="el-icon-plus"
        size="small"
        >新增</el-button
      >
    </div>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { getListAPI, delAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '品牌',
    prop: 'car_brand',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['car_brand']?.['name']}</p>
    }
  },

  {
    label: '车型',
    prop: 'name',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['name']}</p>
    }
  },
  {
    label: '描述',
    prop: 'description',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['description']}</p>
    }
  },

  {
    label: '首字母',
    prop: 'initial',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['initial']}</p>
    }
  },

  {
    label: '操作',
    minWidth: '120',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.model_list_edit)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'CarModelEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.model_list_del)}
            style="margin-left:10px"
            underline={false}
            type="primary"
            onClick={() => this.handleRemove(row)}
          >
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        name: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    // this.getList()
  },
  activated() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  methods: {
    async getList() {
      const { name } = this.searchData
      let params = { page: this.currentPage, page_size: this.pageSize, name }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    handleRemove({ id }) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
      // .el-select .el-input {
      //   width: 100px;
      // }
      // .input-with-select {
      //   vertical-align: middle;
      // }
      // .input-with-select .el-input-group__prepend {
      //   background-color: #fff;
      // }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }
  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
